import React from "react"

import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"
import "../../css/slider-animations.css"
import "../../css/homeslider.css"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const query = graphql`
  {
    banner: allContentfulCareerSliderImages(
      filter: { node_locale: { eq: "en-US" } }
    ) {
      nodes {
        image {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
`

const CareerGallerySlider = props => {
  const {
    banner: { nodes: banners },
  } = useStaticQuery(query)

  return (
    <div className="career-gallery">
      <Slider className="slider-wrapper home-slider2">
        {banners.map((item, index) => (
          <div key={index} className="slider-content ">
            <GatsbyImage image={getImage(item.image)} alt="" />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CareerGallerySlider
