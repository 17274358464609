import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import JobVacancyGallery from "./JobVacancyGallery"
import JobVacancySidebar from "./JobVacancySidebar"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const allJobs = graphql`
  {
    allJobs: allContentfulJobs(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        id
        title
        role
        category
        formLink
        banner {
          gatsbyImageData
        }
      }
      categoriesList: distinct(field: category)
    }
  }
`

const getFilterSections = jobs => {
  const filters = []
  jobs.map(job => {
    const index = filters.findIndex(filter => filter.role === job.role)

    if (index === -1) {
      filters.push({
        title: job.title,
        role: job.role,
        category: job.category,
        banner: job.banner,
      })
    }
  })
  return filters
}

const filterImages = (filterKey, jobs, categoryKey) => {
  const roleFiltered = jobs.filter(job =>
    filterKey === "all" ? job : job.role === filterKey
  )

  const categoryFiltered = roleFiltered.filter(job =>
    categoryKey === "all" ? job : job.category === categoryKey
  )

  return categoryFiltered
}

const JobVaccancy = props => {
  const intl = useIntl()
  const {
    allJobs: { nodes: jobs, categoriesList },
  } = useStaticQuery(allJobs)

  const emptyQuery = ""

  const [state, setState] = useState({
    list: jobs,
    filterKey: "Professionals",
    categoryKey: "all",
    searchResult: [],
    query: emptyQuery,
  })

  const filterCategory = categoryKey => {
    setState({
      ...state,
      categoryKey,
    })
  }

  const { searchResult, list, filterKey, categoryKey } = state

  let filteredList = filterImages(filterKey, list, categoryKey)
  let filters = getFilterSections(list)

  const [searchState, setSearchState] = useState(false)

  const handleInputChange = event => {
    const query = event.target.value

    const { filterKey } = state

    const searchResult = filteredList.filter(job => {
      return (
        job.title.toLowerCase().includes(query.toLowerCase()) &&
        job.role === filterKey
      )
    })

    setState({
      ...state,
      searchResult,
      query,
    })

    setSearchState(true)
  }

  const { query } = state

  const hasResult = searchResult && query !== emptyQuery
  const result = hasResult ? searchResult : filteredList

  const collumnCls = props.col
    ? props.col
    : "card-container col-lg-4 col-md-6 col-sm-6"

  return (
    <div className="content-block job-vacancy">
      <div className="portfolio " id="portfolio">
        <div className="site-filters m-b20 filters2">
          {/* FILTERS */}
          <ul className="filters " data-toggle="buttons">
            {filters.map(filter => (
              <li
                key={filter.id}
                className={filter.role === filterKey ? "btn btn-active" : "btn"}
                onClick={() => setState({ ...state, filterKey: filter.role })}
              >
                <a className="">
                  <i className={filter.icon}></i>
                  <span>{filter.role === 'Professionals' ? intl.formatMessage({ id: "career.jobvacancy.fulltime" }) : intl.formatMessage({ id: "career.jobvacancy.internship" })}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="row">
          <div className="col-12">
            <p className="job-txt">
              <span className="job-txt-icon">
                <i className="ti-info-alt"></i>
              </span>
              {/* {intl.formatMessage({ id: "career.jobvacancy.notice" })}&nbsp;
              <b>{intl.formatMessage({ id: "career.jobvacancy.email" })}</b>&nbsp;
              {intl.formatMessage({ id: "career.jobvacancy.notice2" })} */}
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: "career.jobvacancy.notice",
                  }),
                }}
              ></span>
            </p>
          </div>
        </div>

        <div className="row job-gallery-and-sidebar">
          <div className="col-lg-9 col-md-12 pr-3">
            <JobVacancyGallery
              searchState={searchState}
              result={result}
              filteredList={filteredList}
              collumnCls={collumnCls}
            />
          </div>

          <JobVacancySidebar
            categories={categoriesList}
            filterCategory={filterCategory}
            handleInputChange={handleInputChange}
            categoryKey={categoryKey}
          />
        </div>
      </div>
    </div>
  )
}

export default JobVaccancy
