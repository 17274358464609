import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"

const JobVacancyGallery = ({
  searchState,
  result,
  filteredList,
  collumnCls,
}) => {
  return (
    <div className="portfolio_area">
      <div className={`row sp15 ${!filteredList.length ? "no-jobs" : ""}`}>
        {searchState ? (
          result.map(job => (
            <div key={job.id} className={collumnCls}>
              <a href={job.formLink} target="_blank">
                <div className="dlab-media portbox4 vacancy-banner">
                  <GatsbyImage image={getImage(job.banner)} alt="" />
                </div>
              </a>
            </div>
          ))
        ) : !filteredList.length ? (
          <div className="col-12">
            <p className="job-txt text-center">No Jobs Available</p>
          </div>
        ) : (
          filteredList.map(job => (
            <div key={job.id} className={collumnCls}>
              <a href={job.formLink} target="_blank">
                <div className="dlab-media portbox4 vacancy-banner">
                  <GatsbyImage image={getImage(job.banner)} alt="" />
                </div>
              </a>
            </div>
          ))
        )}
      </div>
    </div>
  )
}

export default JobVacancyGallery
