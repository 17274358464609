import React, { Component } from "react"
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { injectIntl } from "react-intl"

import content from "./career-testimonial-items"

class CareerTestimonial extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      responsive: responsive(),
    }
    return (
      <>
        <Slider
          {...settings}
          className="testimonial-two-dotsl dots-long d-primary btn-style-1"
        >
          {content.map((item, id) => (
            <div
              className="item p-a5 wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="testimonial-9 flex-fill testimonial-container">
                <div className="testimonial-pic radius style1 testi-photo">
                  <img
                    src={item.image}
                    id={item.author === "Ratna Sosialin" ? "flip-img" : ""}
                  />
                </div>
                <div className="testimonial-text testi-desc">
                  <p 
                      className={
                        "text-center" +
                        (this.props.intl.locale === "en" ? "" : " font-italic")
                      }>
                    {item.description}
                  </p>

                  {/* {this.props.intl.locale === "en" ? (
                    <p className="text-center">{item.description}</p>
                  ) : (
                    <p className="text-center">{item.deskripsi}</p>
                  )}
                   */}
                </div>
                <div className="testimonial-detail testi-detail">
                  <strong className="testimonial-name">{item.author}</strong>
                  {/* <span className="testimonial-position">{item.designation}</span> */}
                  
                  <span className="testimonial-position">
                    {this.props.intl.locale === "en" ? item.designation : this.props.intl.locale === "cn" ? item.jabatanCN : item.jabatan}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </>
    )
  }
}
export default injectIntl(CareerTestimonial)
