import React from "react"
import { Accordion } from "react-sanfona"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import "../../css/custom-css/custom-job-vacancy-sidebar.css"

const JobVacancySidebar = ({
  categories,
  filterCategory,
  handleInputChange,
  categoryKey,
}) => {
  const intl = useIntl()
  const changeCategory = categoryKey => {
    filterCategory(categoryKey)
  }
  return (
    <div className="col-lg-3">
      <aside className="side-bar sticky-top">
        <div className="widget widget_archive">
          <div className="widget">
            <div className="search-bx style-1">
              <form role="search" method="post">
                <div className="input-group">
                  <input
                    name="search"
                    className="form-control"
                    placeholder={intl.formatMessage({
                      id: "career.jobvacancy.sidebar.search",
                    })}
                    type="text"
                    id="search"
                    onChange={handleInputChange}
                  />
                  <span className="input-group-btn">
                    <button
                      type="submit"
                      className="fa fa-search text-primary"
                    ></button>
                  </span>
                </div>
              </form>
            </div>
          </div>

          <h5 className="widget-title style-1">
            {intl.formatMessage({
              id: "career.jobvacancy.sidebar.categorieslist",
            })}
          </h5>
          {categories.map((category, index) => {
            return (
              <ul key={index}>
                <Accordion className="accordian">
                  <li>
                    <button
                      style={{ textAlign: "left" }}
                      className={`categoryBtn ${
                        category === categoryKey ? `categoryBtn-active` : ""
                      }`}
                      onClick={() => changeCategory(category)}
                    >
                      {category}
                    </button>
                  </li>
                </Accordion>
              </ul>
            )
          })}
          <ul>
            <Accordion className="accordian">
              <li className="clear-cat">
                <button
                  className="categoryBtn"
                  onClick={() => changeCategory("all")}
                >
                  {intl.formatMessage({
                    id: "career.jobvacancy.sidebar.clear",
                  })}
                </button>
              </li>
            </Accordion>
          </ul>
        </div>
      </aside>
    </div>
  )
}

export default JobVacancySidebar
