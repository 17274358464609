import React, { useState, Component } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"
import CareerTestimonial from "./../components/custom-components/careerTestimonial"
import ScrollToTop from "../components/scrollToTop"
import FloatingBtn from "../components/custom-components/floatingBtn"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import CareerGallerySlider from "../components/custom-components/CareerGallerySlider"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-career.css"

import JobVaccancy from "../components/custom-components/JobVaccancy"
import Seo from "../components/seo"

export const query = graphql`
  {
    under1: contentfulCareerImg(title: { eq: "underbanner1" }) {
      image {
        gatsbyImageData
      }
    }
    under2: contentfulCareerImg(title: { eq: "underbanner2" }) {
      image {
        gatsbyImageData
      }
    }
    under3: contentfulCareerImg(title: { eq: "underbanner3" }) {
      image {
        gatsbyImageData
      }
    }
    undervid: contentfulCareerImg(title: { eq: "underbanner-vid" }) {
      image {
        gatsbyImageData(height: 1200)
      }
    }
    panca1: contentfulCareerImg(title: { eq: "pancaS1" }) {
      image {
        gatsbyImageData
      }
    }
    panca2: contentfulCareerImg(title: { eq: "pancaS2" }) {
      image {
        gatsbyImageData
      }
    }
    panca3: contentfulCareerImg(title: { eq: "pancaS3" }) {
      image {
        gatsbyImageData
      }
    }
    panca4: contentfulCareerImg(title: { eq: "pancaS4" }) {
      image {
        gatsbyImageData
      }
    }
    panca5: contentfulCareerImg(title: { eq: "pancaS5" }) {
      image {
        gatsbyImageData
      }
    }
    act1: contentfulCareerImg(title: { eq: "act1" }) {
      image {
        gatsbyImageData
      }
    }
    act2: contentfulCareerImg(title: { eq: "act2" }) {
      image {
        gatsbyImageData
      }
    }
    perk1: contentfulCareerImg(title: { eq: "perk1" }) {
      image {
        gatsbyImageData(height: 250, width: 250)
      }
    }
    perk2: contentfulCareerImg(title: { eq: "perk2" }) {
      image {
        gatsbyImageData(height: 250, width: 250)
      }
    }
    perk3: contentfulCareerImg(title: { eq: "perk3" }) {
      image {
        gatsbyImageData(height: 250, width: 250)
      }
    }
    perk4: contentfulCareerImg(title: { eq: "perk4" }) {
      image {
        gatsbyImageData(height: 250, width: 250)
      }
    }
  }
`

const Career = () => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  const [isOpen, setOpen] = useState(false)
  const {
    under1,
    under2,
    under3,
    undervid,
    panca1,
    panca2,
    panca3,
    panca4,
    panca5,
    act1,
    act2,
    perk1,
    perk2,
    perk3,
    perk4,
  } = useStaticQuery(query)

  return (
    <div className="skin-1 career">
      <Seo title={intl.formatMessage({ id: "header.career" })} />

      <Header careerPage />
      <FloatingBtn />

      {/* banner */}

      <div className="dlab-bnr-inr bg-pt hero-section home-bnr1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12">
              <div className="hero-content">
                <h1 className="title">
                  {intl.formatMessage({ id: "career.banner.title" })}
                </h1>

                <div className="buttons">
                  <a href="#availJobs">
                    {intl.formatMessage({ id: "career.banner.btn" })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* collages */}
      
      <div className="section-full" id="choose-us">
        <div className="row m-lr0">
          <div
            className="col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="row spno">
              <div className="mozaic-top col-lg-6 col-md-6 col-sm-6 bg-white d-flex first-mozaic">
                <div className="dlab-services-box text-black">
                  <h3>
                    {intl.formatMessage({ id: "career.collage.1.title" })}
                  </h3>
                  <p className="title m-b0">
                    {intl.formatMessage({ id: "career.collage.1.desc" })}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under1.image)}
                  imgStyle={{ height: "auto" }}
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under3.image)}
                  imgStyle={{ height: "auto" }}
                  alt=""
                />
              </div>
              <div className="mozaic-bot col-lg-6 col-md-6 col-sm-6 bg-white d-flex">
                <div className="dlab-services-box text-black">
                  <h3 className="title m-b15">
                    {intl.formatMessage({ id: "career.collage.2.title" })}
                  </h3>
                  <p className="title m-b0">
                    {intl.formatMessage({ id: "career.collage.2.desc" })}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="mozaic-top col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="row spno">
              <div className="col-lg-6 col-md-6 col-sm-6 bg-white d-flex">
                <div className="dlab-services-box text-black">
                  <h3 className="title m-b15">
                    {intl.formatMessage({ id: "career.collage.3.title" })}
                  </h3>
                  <p className="title m-b0">
                    {intl.formatMessage({ id: "career.collage.3.desc" })}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <GatsbyImage
                  image={getImage(under2.image)}
                  imgStyle={{ height: "auto" }}
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="video-bx radius-no h100">
                  <GatsbyImage
                    image={getImage(undervid.image)}
                    imgStyle={{ height: "auto" }}
                    alt=""
                  />
                  <div className="video-play-icon" id="mozaic-video">
                    <Link
                      to="#"
                      onClick={e => {
                        e.preventDefault()
                        setOpen(true)
                      }}
                      className="popup-youtube video bg-primary"
                    >
                      <i className="fa fa-play"></i>
                    </Link>
                  </div>
                </div>
              </div>
              <div className=" mozaic-bot col-lg-6 col-md-6 col-sm-6 bg-white d-flex last-mozaic">
                <div className="dlab-services-box text-black">
                  <h3 className="title m-b15">
                    {intl.formatMessage({ id: "career.collage.4.title" })}
                  </h3>
                  <p className="title m-b0">
                    {intl.formatMessage({ id: "career.collage.4.desc" })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          allowFullScreen
          isOpen={isOpen}
          videoId="TKfOI8xFQNk"
          onClose={() => setOpen(false)}
        />
      )}

      {/* panca sradha */}

      <div className="section-full bg-white content-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title text-center m-b50">
                <h2>
                  {intl.formatMessage({ id: "career.pancasradha.title" })}
                </h2>
              </div>
              <div className="section-content box-sort-in p-b0 button-example">
                <div className="row justify-content-center">
                  <div className="col-12 col-lg-4 m-b30">
                    <div className="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca1.image)} alt="" />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.pancasradha.1.title",
                          })}
                        </h5>
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "career.pancasradha.1.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 m-b30">
                    <div className="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca2.image)} alt="" />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.pancasradha.2.title",
                          })}
                        </h5>
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "career.pancasradha.2.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 m-b30">
                    <div className="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca3.image)} alt="" />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.pancasradha.3.title",
                          })}
                        </h5>
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "career.pancasradha.3.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 m-b30">
                    <div className="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca4.image)} alt="" />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.pancasradha.4.title",
                          })}
                        </h5>
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "career.pancasradha.4.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 m-b30">
                    <div className="icon-bx-wraper center panca-sradha">
                      <GatsbyImage image={getImage(panca5.image)} alt="" />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.pancasradha.5.title.1",
                          })}
                          <br />
                          {intl.formatMessage({
                            id: "career.pancasradha.5.title.2",
                          })}
                        </h5>
                        <p className="text-center">
                          {intl.formatMessage({
                            id: "career.pancasradha.5.desc",
                          })}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* actcom */}

      <div
        className="section-full content-inner-2 wow fadeIn activities-communities"
        data-wow-duration="2s"
        data-wow-delay="0.6s"
      >
        <div className="content-block">
          <div className="portfolio actcoms" id="portfolio">
            <div className="portfolio_area">
              <div className="row sp15 actcom">
                <div className="card-container col-lg-6 col-md-6 col-sm-12">
                  <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox3">
                    <GatsbyImage image={getImage(act1.image)} alt="" />
                    <div className="overlay-bx">
                      <div className="portinner">
                        <div className="port-up">
                          <h3 className="port-title">
                            {intl.formatMessage({
                              id: "career.activities.title",
                            })}
                          </h3>
                        </div>
                        <div className="port-down">
                          <Link
                            to={`${locale}/activities-communities`}
                            className="btn-link"
                          >
                            {intl.formatMessage({
                              id: "career.activities.btn",
                            })}{" "}
                            <i className="la la-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-container col-lg-6 col-md-6 col-sm-12">
                  <div className="dlab-media dlab-img-overlay1 dlab-img-effect portbox3">
                    <GatsbyImage image={getImage(act2.image)} alt="" />
                    <div className="overlay-bx">
                      <div className="portinner">
                        <div className="port-up">
                          <h3 className="port-title">
                            {intl.formatMessage({
                              id: "career.communities.title",
                            })}
                          </h3>
                        </div>
                        <div className="port-down">
                          <Link
                            to={`${locale}/activities-communities`}
                            className="btn-link"
                          >
                            {intl.formatMessage({
                              id: "career.activities.btn",
                            })}{" "}
                            <i className="la la-arrow-right"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* perks */}

      <div className="section-full content-inner bg-white benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="title text-center m-b50">
                <h2>
                  {intl.formatMessage({
                    id: "career.perks.title",
                  })}
                </h2>
              </div>
              <div className="section-content box-sort-in p-b0 button-example">
                <div className="row justify-content-around">
                  <div className="col-6 col-md-2 col-lg-2 m-b30">
                    <div className="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        alt=""
                        image={getImage(perk1.image)}
                        // width={330}
                        // height={330}
                      />
                      <div className="icon-content">
                        <h5
                          className="dlab-tilte text-capitalize"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: "career.perks.1",
                            }),
                          }}
                        ></h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-2 col-lg-2 m-b30">
                    <div className="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        alt=""
                        image={getImage(perk2.image)}
                        // width={330}
                        // height={330}
                      />
                      <div className="icon-content">
                        <h5 className="dlab-tilte text-capitalize">
                          {intl.formatMessage({
                            id: "career.perks.2",
                          })}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-2 col-lg-2 m-b30">
                    <div className="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        alt=""
                        image={getImage(perk3.image)}
                      />
                      <div className="icon-content">
                        <h5
                          className="dlab-tilte text-capitalize"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: "career.perks.3",
                            }),
                          }}
                        ></h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-2 col-lg-2 m-b30">
                    <div className="icon-bx-wraper center">
                      <GatsbyImage
                        className="benefitImg"
                        alt=""
                        image={getImage(perk4.image)}
                      />
                      <div className="icon-content">
                        <h5
                          className="dlab-tilte text-capitalize"
                          dangerouslySetInnerHTML={{
                            __html: intl.formatMessage({
                              id: "career.perks.4",
                            }),
                          }}
                        >
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* workers testimony */}

      <div className="section-full bg-gray content-inner-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="sort-title clearfix text-center">
                <h2>
                  {intl.formatMessage({
                    id: "career.workerstestimony.title",
                  })}
                </h2>
              </div>
            </div>
          </div>

          <div className="section-content">
            <CareerTestimonial />
          </div>
        </div>
      </div>

      {/* <div
          className="section-full dlab-we-find bg-img-fix p-b20 bg-white wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="0.6s"
        >
          <div className="section-content">
            <CareerGallerySlider contentWrapperCls="sliderStyle3" hideBtn />
          </div>
        </div> */}

      {/* job vacancy */}
      
      <div id="availJobs"></div>
      <div style={{ marginTop: "5rem" }}>
        <CareerGallerySlider contentWrapperCls="sliderStyle3" hideBtn />
      </div>
      <div
        // id="availJobs"
        className="section-full bg-white content-inner-2 wow fadeIn"
        data-wow-duration="2s"
        data-wow-delay="0.6s"
      >
        <div className="container">
          <div className="row p-l0 sp10">
            <div className="col-lg-12">
              <JobVaccancy />
            </div>
          </div>
        </div>
      </div>
      <Footer hideContactInfo />
      <ScrollToTop className="icon-up" />
    </div>
  )
}
export default Career
